import React from 'react';

import GlyphSocialFbSimple from '@dolstaff/shared/es/icons/GlyphSocialFbSimple';
import GlyphSocialInstagram from '@dolstaff/shared/es/icons/GlyphSocialInstagram';
import GlyphSocialYoutube from '@dolstaff/shared/es/icons/GlyphSocialYoutube';
import styled from 'styled-components';

import { Container, Section } from 'components/withMarketingSection';
import DolInfo from 'shared/DolInfo';

const Heading = styled.div`
  font-size: 32px;
  font-weight: bold;
  line-height: 1.2em;
  margin-top: 0.5em;

  @media screen and (min-width: 1024px) {
    font-size: 40px;
  }
`;

const Main = styled.div`
  margin-top: 6em;

  .itemWrp {
    margin-bottom: 1em;
  }

  .content {
    border-top: 1px solid #2d3742;
    padding-top: 1em;
  }

  .body {
    margin-bottom: 1em;

    a {
      color: black;
    }
  }

  @media screen and (min-width: 1024px) {
    display: flex;
    margin-top: 8em;
    margin-left: -1em;
    margin-right: -1em;

    .itemWrp {
      width: calc(100% / 3 - 2em);
      margin-left: 1em;
      margin-right: 1em;
      margin-bottom: 2em;
      &.item-title {
        margin-right: 130px;
      }
    }

    .content {
      margin-top: 3em;
      padding-top: 2em;
    }
  }
`;

const SocialButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  text-align: center;
  color: black;
`;

class OurStoryFooter extends React.PureComponent {
  render() {
    return (
      <Section>
        <Container>
          <Main>
            <div className="itemWrp item-title">
              <Heading>Join us and be a better you</Heading>
            </div>
            <div className="itemWrp">
              <div className="content">
                <div className="body">
                  <DolInfo>
                    {dolInfo => (
                      <a href={`tel:${dolInfo.phone}`}>{dolInfo.phone}</a>
                    )}
                  </DolInfo>
                  <br />
                  <a
                    href="https://g.page/dolenglish?share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    458/14 Đường 3/2, P12, Q10, TPHCM
                  </a>
                </div>
                <div>
                  <SocialButton
                    href="https://www.facebook.com/dolenglish.ieltsdinhluc"
                    target="_blank"
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    <GlyphSocialFbSimple size={16} />
                  </SocialButton>
                  <SocialButton
                    href="https://www.youtube.com/channel/UChpTmCcexiro5tbEp4Yhxyg"
                    target="_blank"
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    <GlyphSocialYoutube size={16} />
                  </SocialButton>
                  <SocialButton
                    href="https://www.instagram.com/dolenglish"
                    target="_blank"
                    style={{ display: 'inline-block' }}
                  >
                    <GlyphSocialInstagram size={16} />
                  </SocialButton>
                </div>
              </div>
            </div>
            <div className="itemWrp">
              <div className="content">
                © 2020 DOL English. All rights reserved.
              </div>
            </div>
          </Main>
        </Container>
      </Section>
    );
  }
}

OurStoryFooter.propTypes = {};

export default OurStoryFooter;
