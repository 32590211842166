import React from 'react';

// import Image from 'shared/Image';
import styled from 'styled-components';

import { Container } from 'components/withMarketingSection';
import { BigTitle } from 'shared/Marketing/Shared';
import MyButton from 'shared/MyButton';
import getAssetLink from 'utils/getAssetLink';

class HeroBanner extends React.PureComponent {
  render() {
    return (
      <Section>
        <Container>
          <HeroContainer>
            <HeroContent>
              <BigTitle style={{ marginBottom: '0.5em' }}>
                7 lý do bạn sẽ muốn trở thành 1 phần của DOL IELTS Đình Lực.
              </BigTitle>
              <MyButton href="#apply-now">Ứng tuyển ngay</MyButton>
            </HeroContent>
            <img
              src={getAssetLink({ item: { handle: 'JLZQ19WIRliZt6QpHkoj' } })}
              alt="apply-now"
            />
          </HeroContainer>
        </Container>
      </Section>
    );
  }
}

HeroBanner.propTypes = {};

export default HeroBanner;

const Section = styled.section`
  background: linear-gradient(0deg, #fff7eb, #fff7eb),
    radial-gradient(
      84.71% 84.71% at 87.81% 89.36%,
      #feeae7 41.79%,
      rgba(212, 233, 255, 0.15) 100%
    );
`;

const HeroContent = styled.div`
  max-width: 80%;

  h1 {
    /* BigTitle */
    color: #760a2d;
    font-size: 32px;
    line-height: 1.2;
  }

  @media screen and (min-width: 769px) {
    max-width: 300px;

    h1 {
      font-size: 36px;
      line-height: 1.5;
    }
  }

  @media screen and (min-width: 1141px) {
    max-width: unset;
    align-self: start;
    margin-top: 8rem;
  }
`;

const HeroContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  transition: all 0.2s;

  @media screen and (min-width: 769px) {
    grid-template-columns: repeat(20, 1fr);
    grid-gap: 0;
    padding-top: 50px;

    ${HeroContent} {
      grid-column: 1 / 8;
      grid-row: 1 / 2;
    }

    img {
      grid-column: 7 / 20;
      grid-row: 1 / 2;
    }
  }

  @media screen and (min-width: 1141px) {
    align-items: end;
    height: 764px;
    padding-top: 0;
  }
`;
