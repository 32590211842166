import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import LandingTeacher from '../shared/LandingTeacher';
import Footer from '../shared/LandingTeacher/Footer';
import HeroBanner from '../shared/LandingTeacher/HeroBanner';
import { HeroBannerV2 } from '../shared/LandingTeacher/HeroBannerV2';
import JobList from '../shared/LandingTeacher/JobList';
import MainLayout from '../shared/MainLayout';
import MySEO from '../shared/MySEO';
import { FooterRecruitment } from '../shared/LandingTeacher/FooterRecruitment';

class PageTeacher extends React.PureComponent {
  render() {
    const { jobList, facebookReviewList } = this.props.data.gcms;

    return (
      <MainLayout showFooterRegister={false} noMenu noFooter hideSideCTA>
        <MySEO
          title="Tuyển dụng tại DOL - Cơ hội việc làm hấp dẫn"
          description="Cơ hội trở thành 1 phần của DOL IELTS Đình Lực với quyền lợi cực kỳ hấp dẫn"
          featureImg="Q4QoRUtQs23AuUeS2h8w"
        />
        <HeroBannerV2 />
        <JobList jobList={jobList} />
        <LandingTeacher facebookReviewList={facebookReviewList} />
        <FooterRecruitment />
      </MainLayout>
    );
  }
}

PageTeacher.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      jobList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      facebookReviewList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    })
  })
};

export default PageTeacher;

export const pageQuery = graphql`
  query {
    gcms {
      facebookReviewList: facebookReviews(orderBy: order_ASC) {
        reviewUrl
      }

      jobList: atwJobs(
        stage: PUBLISHED
        where: { domainType: DolEnglish }
        orderBy: order_ASC
      ) {
        stage
        updatedAt
        createdAt
        id
        team
        title
        address
        order
        salaryDisplay
        reasonToJoin1
        reasonToJoin2
        reasonToJoin3
        jobDescription {
          html
        }
        requirement {
          html
        }
        benefit {
          html
        }
        aboutCompany
        banner {
          stage
          updatedAt
          createdAt
          id
          handle
          fileName
          height
          width
          size
          mimeType
          altText
          title
        }
        summary
        slug
        type
      }
    }
  }
`;
