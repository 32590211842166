import * as React from 'react';

import { colorsV2 } from 'style/colors-v2';
import { withHomePageSection } from 'components/withHomePageSection';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { LazyImage } from 'components/LazyImage';
import DolInfo from 'shared/DolInfo';
import Button from '@dolstaff/shared/es/Button';
import { Link } from 'gatsby';
import { fromScreen } from 'utils/media-query/responsive.util';
import ProgressiveImage from 'react-progressive-image';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

const Main = styled.div`
  padding: 32px 0;
  .content {
    .info {
      h1 {
        margin-bottom: 24px;
      }
      position: relative;
      .desc {
        margin-top: 20px;
        margin-bottom: 24px;
      }
      button {
        border-radius: 8px;
      }
      .icon {
        display: none;
        position: absolute;
        bottom: -80px;
        left: 86px;
        transform: rotate(22deg);
        img {
          transform: scaleX(-1);
        }
      }
      .highlight {
        position: relative;
        z-index: 1;
        &::after {
          content: '';
          position: absolute;
          background: ${colorsV2.yellow100};
          bottom: 3px;
          left: 0;
          height: 10px;
          width: 100%;
          opacity: 0.3;
          z-index: -1;
        }
      }
    }

    .banner {
      .banner-image {
        width: 100%;
      }
      display: none;
      position: relative;
      .icon {
        position: absolute;
        &.top {
          top: 0;
          right: 0;
        }
        &.bottom {
          display: none;
          right: 0;
          bottom: 0;
        }
      }
    }
  }

  ${fromScreen(776)} {
    padding: 100px 0;

    .content {
      display: grid;
      gap: 20px;
      grid-template-columns: 392px 1fr;
      align-items: center;

      .banner {
        display: block;
      }
    }
  }

  ${fromScreen(1144)} {
    /* min-height: 852px; */
    .content {
      grid-template-columns: 576px 1fr;
      gap: 68px;
      .info {
        .icon {
          display: block;
        }
        .desc {
          margin-top: 32px;
          margin-bottom: 36px;
        }
      }
      .banner {
        .icon {
          &.top {
            left: 0;
          }
          &.bottom {
            display: block;
          }
        }
      }
    }
  }
`;

const HeroBannerBase = () => {
  return (
    <Main>
      <div className="content">
        <div className="info">
          <Typography
            variant="bold/32-40"
            tabletVariant="bold/40-48"
            desktopVariant="bold/48-60"
            v3
            as="h1"
          >
            <span className="highlight">Tuyển dụng tại</span> DOL
            <br /> English - IELTS Đình Lực
          </Typography>

          <DolInfo>
            {({ dolInfo }) => (
              <a href="#job-list">
                <Button
                  size="large"
                  className="btn-action"
                  type="primary"
                  marginBottom="0"
                >
                  <Typography
                    variant="semi-bold/14-24"
                    color={colorsV2.white100}
                  >
                    Ứng tuyển ngay
                  </Typography>
                </Button>
              </a>
            )}
          </DolInfo>
          <LazyImage
            handle="pZtoUY3mStuWmaQ5lBx6"
            className="icon left"
            alt="icon-arrow"
          />
        </div>
        <div className="banner">
          <ProgressiveImage
            src={getAssetLinkByHandle({ handle: 'OFW5HihS2aRHLMnKybG6' })}
            placeholder={getAssetLinkByHandle({
              handle: 'OFW5HihS2aRHLMnKybG6',
              width: 10
            })}
          >
            {src => (
              <img src={src} className="banner-image" alt="Giáo viên tại DOL" />
            )}
          </ProgressiveImage>

          <LazyImage
            handle="RY1uavGWQqW2aZ0GGJDo"
            className="icon top"
            alt="icon-start"
          />
          <LazyImage
            handle="1KmuTBLmS4uEu98y6Tp3"
            className="icon bottom"
            alt="icon-wavy"
          />
        </div>
      </div>
    </Main>
  );
};

export const HeroBannerV2 = withHomePageSection(HeroBannerBase, {
  style: {
    backgroundColor: colorsV2.paper
  }
});
