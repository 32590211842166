import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Image from '../../Image';
// import { BigSection } from '../../Marketing/Shared';
import WhiteButton from '../../MyButton/WhiteButton';
import TeacherTitle from '../TeacherTitle';
import { CardJob } from '../CardJob';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div``;

const Container = styled.div`
  padding: 32px 16px;
  ${fromScreen(458)} {
    padding: 100px 16px 16px;
  }
  ${fromScreen(776)} {
    padding: 100px 32px 32px;
  }
`;

const WhiteBox = styled.div`
  border: 1px solid white;
  overflow: hidden;
`;

const CardJobList = styled.div`
  display: grid;
  gap: 24px;
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromScreen(1144)} {
    max-width: 1080px;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
  }
`;

const ButtonWrp = styled.div`
  text-align: center;
  margin-top: 2em;

  @media screen and (min-width: 1024px) {
    margin-top: 4em;
  }
`;

const artJob = {
  handle: 'VXc2S6RWRMuu5Se7nqMe',
  width: 416,
  height: 175
};

class JobList extends React.PureComponent {
  render() {
    const { jobList } = this.props;

    return (
      <Container id="job-list">
        <Main>
          <TeacherTitle>
            <div className="artIcon" style={{ width: '100px' }}>
              <Image image={artJob} maxWidth={100} />
            </div>
            See the latest job openings
          </TeacherTitle>

          <WhiteBox>
            <CardJobList>
              {jobList.map((job, key) => (
                <CardJob
                  key={key}
                  title={job.title}
                  address={job.address}
                  salary={job.salaryDisplay}
                  slug={`job/${job.slug}`}
                />
              ))}
            </CardJobList>
          </WhiteBox>

          <ButtonWrp style={{ display: 'none' }}>
            <WhiteButton>See more jobs</WhiteButton>
          </ButtonWrp>
        </Main>
      </Container>
    );
  }
}

JobList.propTypes = {
  jobList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default JobList;
